@import '/styles/vars';

.container {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2147483647;
    transition: opacity $ease;
    pointer-events: none;
    opacity: 0;
    background: $color_modal_bg;
    &:after {
        content: '';
        position: absolute;
        border: 3px solid rgba(255, 255, 255, 0.2);
        border-top: 3px solid #fff;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        animation: spin 1s linear infinite;
        left: 50%;
        top: 50%;
        margin: -30px 0 0 -30px;
    }
}

.show {
    pointer-events: all;
    opacity: 1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
